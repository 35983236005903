/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import Moment from 'moment';
import 'moment/locale/ko';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import CardsFooter from 'components/Footers/CardsFooter';
import { withTranslation } from 'react-i18next';

// style
const mainSection1 = { height: '350px' };

class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      content: "",
      views: 0,
      published: false,
      create_date: '',
      update_date: '',
      noticeImages: window.history.state.noticeImages,
      isMine: window.history.state.isMine
    };
  }

  onUpdateViews = () => {

    const submitPrep = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        title: window.history?.state?.title,
        content: window.history?.state?.content,
        published: String(window.history?.state?.published),
        noticeImages: window.history?.state?.noticeImages,
        views: String(window.history?.state?.views + 1)
      })
    };

    fetch(
      `${process.env.REACT_APP_API_HOST}/notice/${window.history?.state?.noticeId}`,
      submitPrep
    ).then(
      response => response.json()
    ).then(
      data => {
        if (data.message) {
          console.log(data.message);
          return;
        }
        this.onFetchNotice();
      }
    ).catch(err => console.log(err));
  };

  onFetchNotice = () => {

    fetch(
      `${process.env.REACT_APP_API_HOST}/notice/${window.history?.state?.noticeId}`,
      { method: 'GET' }
    ).then(
      response => response.json()
    ).then(
      data => {
        if (data.ok) {
          console.log('data on fetch', data.notice);
          this.setState({
            title: data.notice.title,
            content: data.notice.content,
            views: data.notice.views,
            published: data.notice.published,
            noticeImages: data.notice.NoticeImages,
            create_date: data.notice.createdAt,
            update_date: data.notice.updateDate,
          })
        }
      }
    ).catch(err => console.log(err, 'Detect URI MalformError(content value): ' + this.state.content));
  };

  onDecodeTheText = (contextVar) => {
    try {
      const result = contextVar ? decodeURIComponent(contextVar) : '';
      return result;
    } catch (error) {
      console.log({
        errorMessage: error,
        contextValue: contextVar
      })
    }
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.onUpdateViews();
  }

  render() {
    const { t } = this.props;
    const imagess = require.context('assets/img/noticeImages/', true);

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section
            className="section-profile-cover section-shaped my-0"
            style={mainSection1}
          >
            <div className="shape shape-style-1 shape-default alpha-4"></div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-3 align-self-lg-center" lg="12">
                      <div className="mt-lg-0">
                        <Card className="my-5">
                          <CardBody>
                            <CardText>
                              <small className="text-muted">
                                {t('notice')}
                              </small>
                            </CardText>
                            <CardTitle tag="h5">
                              {this.state.title}
                            </CardTitle>
                            <CardText>
                              <small className="text-muted">
                                {t('writeDate')}:{' '}
                                {Moment(this.state.create_date).format(
                                  'yyyy-MM-DD HH:mm:ss',
                                )}{' '}
                                {this.state.update_date == null
                                  ? ('')
                                  : (' | ' +
                                    t('updateDate') +
                                    ': ' +
                                    Moment(this.state.update_date).format(
                                      'yyyy-MM-DD HH:mm:ss',
                                    ))}{' '}
                                {this.state.views == null
                                  ? ''
                                  : ' | ' +
                                  t('boardForm.views') +
                                  ': ' +
                                  this.state.views
                                }

                              </small>
                            </CardText>
                            <CardText style={{ whiteSpace: 'pre-wrap' }}>
                              {this.onDecodeTheText(this.state.content)}
                            </CardText>
                            {this.state.noticeImages ? (this.state.noticeImages.map((images, index) => {
                              return (
                                <Card key={index}>
                                  <img
                                    alt="..."
                                    src={imagess(`./${images.imagePath}`)}
                                    style={{ width: 'full', height: 'auto', maxWidth: 'full', maxHeight: '100%' }}
                                  />
                                </Card>
                              )
                            }
                            )) : ('')}

                            <CardText>&nbsp;</CardText>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                window.location.href = '/notice';
                              }}
                            >
                              {t('listButtonLabel')}
                            </Button>


                            <Button
                              color="info"
                              size="sm"
                              style={{ display: this.state.isMine ? 'inline' : 'none' }}
                              onClick={(e) => {
                                window.history.pushState({
                                  isUpdate: true,
                                  noticeId: window.history?.state?.noticeId,
                                  title: this.state.title,
                                  content: this.state.content,
                                  noticeImages: this.state.noticeImages,
                                  published: this.state.published,
                                  views: this.state.views
                                },
                                  "",
                                  "/write");
                                window.location.href = '/write';
                              }}
                            >
                              {t('updateButtonLabel')}
                            </Button>
                            <Button
                              color="danger"
                              size="sm"
                              style={{ display: this.state.isMine ? 'inline' : 'none' }}
                              onClick={(e) => {
                                if (window.confirm('Are you sure you want to delete this notice from database?')) {
                                  // delete it!
                                  fetch(
                                    `${process.env.REACT_APP_API_HOST}/notice/${window.history?.state?.noticeId}`,
                                    {
                                      method: 'DELETE',
                                      headers: {
                                        'Content-Type': 'application/json',
                                        'authorization': 'Bearer ' + localStorage.getItem('token')
                                      }
                                    }
                                  ).then(response => response.json())
                                    .then(data => {
                                      if (data.ok) {
                                        window.location.href = '/notice';
                                      }
                                    });
                                } else {
                                  // Do nothing!
                                  console.log('Cancel delete');
                                  return
                                }
                              }}
                            >
                              {t('deleteButtonLabel')}
                            </Button>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default withTranslation()(Detail);
